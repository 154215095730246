import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import AwardItem from "./awardItem";
import AABC from "../images/award-logos/aabc.svg";
import MANA from "../images/award-logos/mana.svg";
import IBCLC from "../images/award-logos/ibclc.svg";
import NACPM from "../images/award-logos/nacpm.svg";


const Awards = () => {
  const data = {}
  return (
    <div className="bg-neutral-500">
      <div className="container mx-auto">
        <div className="flex flex-row md:items-center items-start self-auto gap-5 py-6">
          {/* <div className="flex flex-row items-center self-auto md:gap-5 gap-2">
            <p className="font-display text-display-xs italic text-white opacity-50 vertical-rl -rotate-180">
              Awards
            </p>
            <hr className="md:w-16 w-6  text-white opacity-50"></hr>
          </div> */}
          <div className="lg:flex lg:flex-row grid md:grid-cols-2 grid-cols-1 grow xl:gap-16  md:gap-x-10 md:gap-y-8 gap-6">
            <AwardItem
                logo={AABC}
                title='American Association of Birth Centers'
                year='Member'
              />
            <AwardItem
                logo={MANA}
                title="Midwives Alliance of North America"
                year='Member'
              />
            <AwardItem
                logo={IBCLC}
                title="International Board Certified Lactation Consultant"
                year='Certified Member'
              />
            <AwardItem
              logo={NACPM}
              title="National Association of Certified Professional Midwives"
              year='Certified Member'
            />
            {/* {data.allAwardsJson.nodes.map((node) => (
              <AwardItem
                key={node.id}
                logo={node.logo.publicURL}
                title={node.title}
                year={node.year}
              />
            ))} */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Awards;
