import React from "react";

import ArchitectureIcon from "../images/service-icons/interior-design.svg";
import RenovationIcon from "../images/service-icons/building-renovation.svg";
import ConstructionIcon from "../images/service-icons/construction.svg";
import Eyebrow from "./eyebrow";
import ServiceItem from "./serviceItem";

const Services = () => {
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow label="OUR SERVICES" />
              <h2 className="font-display md:text-display-xl text-display-md pt-5">
                We provide <span className="italic">exclusive care</span>{" "}
                for women at any stage
              </h2>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-8">
            <ServiceItem
              title="Maternity Care"
              description="Caring for women is our passion. As experts in normal pregnancy and birth, we monitor the physical well being of you and your baby, as well as
                address important psychological and emotional facets of this life-changing journey."
              learnMore="/services"
            />

            <ServiceItem 
              title="Lactation"
              description="Breastfeeding has significant nutritional, physical and emotional benefits for both mom and baby and is proven to reduce the number of ear infections, improve immune function, reduce the risk of obesity, asthma, and cancer."
              learnMore="/services"
            />

            <ServiceItem 
              title="Well Women Support"
              description="We can provide a comfortable environment for annual exams, pap smears, clinical breast exams and more, with an emphasis on nutrition, preventative medicine, fitness and early detection. 
                Preconception counseling, birth control options and testing for sexually transmitted infections are included."
              learnMore="/services"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
