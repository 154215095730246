import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import AwardBadge from "../images/award-badge.svg";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "headshot.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="MEET YOUR MIDWIFE" />
            <h2 className="font-display md:text-display-xl text-display-md pb-4 space-y-0">
              Robin Lommori<br />
              <span class="text-display-xs md:text-display-sm relative -top-4 md:-top-6">LM, CPM, IBCLC</span>
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Robin has served out of hospital mothers for 6 years. Robin’s warm, straightforward nature and extensive experience in midwifery and lactation instantly puts clients at ease. 
              She deeply believes in your ability to make the best decisions for you and your family. 
              She is committed to evidence-based midwifery and provides the research and information you need to choose the best path for you and your baby.
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Robin supports more than just the physical aspects of your pregnancy and birth, offering guidance, connection, and humor at each appointment. 
              Her journey began when she herself became a mother-- and had her empowering out-of-hospital VBAC with her third child. 
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Robin is a licensed midwife with the State of Arizona, and certified through the National Association of Registered Midwives. 
              She is an International Board Certified Lactation Consultant, and trained in both Basic Life Support (BLS) and Neonatal Resuscitation (NRP). 
            </p>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
