import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import AwardBadge from "../images/award-badge.svg";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "pregnancy.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="ABOUT US" />
            <h2 className="font-display md:text-display-xl text-display-md pb-4">
              We believe in developing trusting, <span className="italic">personal</span> relationships with 
              the women and families we serve
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Our Midwives hold extensive training in out-of-hospital births including waterbirths, breech births and home births, 
              working for years under the preceptorship of senior midwives at both home births and birth centers around the country.
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              We hold bachelor's degrees, followed by midwifery degrees from accredited midwifery programs,
              and are certified in Neonatal Resuscitation (NRP), Basic Life Support (BLS), Advanced Fetal Monitoring, and Advanced Life Support in Obstetrics (ALSO).
              We also provide lactation consultation by International Board Certified Lactation Consultants (IBCLC), the highest standard in lactation support.
            </p>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
