import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import WorkItem from "./workItem";
import Eyebrow from "./eyebrow";
import Button from "./button";

const Works = () => {
  const data = useStaticQuery(graphql`
    {
      allWorksJson {
        nodes {
          id
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                width: 592
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col gap-12 lg:py-28 md:py-24 py-12">
          <div className="grid xl:grid-cols-12 grid-cols-1 xl:gap-8 gap-8 items-center">
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10">
              <div className="flex flex-col gap-6">
                <Eyebrow label="PAST CLIENTS" />
                <h4 className="font-display md:text-display-sm text-display-xs font-normal">
                  My birth was <span className="italic">everything</span> I hoped it would be!
                </h4>
                Robin made me feel prepared and comfortable all throughout pregnancy which made labor and delivery an amazing experience! Robin was a wonderful advocate and support during labor. She also made sure that my husband was the most involved and supportive he could be during my delivery process. I highly recommend Robin for your birth! 
                <div>Chloe</div>
              </div>
              <div className="flex flex-col gap-6">
                <h4 className="font-display md:text-display-sm text-display-xs font-normal">
                  The birth was a beautiful experience and while it wasn't like I had planned, I had <span class='italic'>complete peace</span>.
                </h4>
              Robin is the best Doula & Midwife! She is so full of knowledge and had answers and recommendations at every turn. She provided me with great 8nfo all through pregnancy, during labor, and during post-partum. I had planned a home birth, but ended up needing to have a hospital birth so I could be induced due to pre-eclampsia. Robin was the one constant during my pregnancy and birth experience.<br></br><br></br> She came with me to the hospital and helped me understand all the processes and procedures that the hospital wanted to perform. I felt like I was fully informed and able to make decisions according to my beliefs and what was best for my body and baby. The birth was a beautiful experience and while it wasn't like I had planned, I had complete peace. Robin's calm and confident knowledge empowered me to know I was making the best decisions to have that peace. I would definitely recommend her!
              <div>Sarah</div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:px-14 gap-6">
              <h4 className="font-display md:text-display-sm text-display-xs font-normal">
                  Robin walked us through all the options available and was <span className="italic">100% supportive</span> of whatever option we chose.
                </h4>
              She is absolutely AMAZING! From the moment we found out we were expecting to weeks after our daughter was born, Robin kept us grounded. I had never planned on getting pregnant, so I had no idea what to expect or what was normal. All the books and articles were overwhelming, but Robin answered the questions I had as they came and took away all the stress. It got to the point where I could ask her how much I need to stress about something and she would give me an honest answer on a scale of 1 to 10.<br></br><br></br>

              When I was finally in labor, she was an anchor for both me and my husband. My husband's exact words when she walked in the room were, “Thank God! Robin’s here!” Everything she said to me as I was having contractions to pushing to the first moments of having my daughter in my arms was filled with the exact wisdom and strength I needed. I know without a shadow of a doubt that had she not been with us through the whole ordeal, we would have made decisions we would later regret. I look back at my pregnancy (which was no picnic at all) and labor and delivery with confidence that we did what was best for me and my daughter. I can even compare my experience to all my friends who were pregnant at the same time and know that my experience was the most positive of all of them! <br></br><br></br>

              My husband and I cannot recommend Robin enough! She is the literal best option and will make sure your experience is the best it can possibly be!
              <div>Nicole</div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
